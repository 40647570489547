define("zipbooks/routes/main/payroll", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.permissions.all(['account.read', 'users.read'])) {
        this.transitionTo('main.dashboard');
      }
    },
    model: function model() {
      var _this = this;

      return this.client.GET('integrations/gusto').then(function (resp) {
        if (resp.data.length === 0) {
          return null;
        } else {
          _this.store.pushPayload('gusto-integration', resp);

          return _this.store.peekRecord('gusto-integration', resp.data.id);
        }
      }, function () {});
    },
    afterModel: function afterModel(model) {
      if (model && (!model.externalCompanyUuid || !model.finishedOnboarding)) {
        this.overlay.showDialog('overlays/dialogs/gusto-onboarding', 'Gusto Setup', {
          customHeader: true,
          gustoModel: model
        });
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});